/* @font-face {
  font-family: 'LEMON-MILK';
  src: url(../public/assets/fonts/LEMONMILK-Regular.otf);
}

@font-face {
  font-family: 'Century-Gothic';
  src: url(../public/assets/fonts/CenturyGothic.ttf);
} */

#root {
  height: 100%;
}


html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #0A1833;
  overflow-x: hidden;
  color: #fff;
}


a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}


.fs-12 {
  font-size: 12px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.dark-text {
  color: #0D0703 !important;
}

.light-white {
  color: rgba(255, 255, 255, .7) !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}


p {
  font-size: 18px;
  line-height: 150%;
}

p span {
  background: linear-gradient(-5deg, #FFD400, #F27A01);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

h1 {
  font-size: 64px;
  font-weight: 800;
}

h1 span {
  background: linear-gradient(-5deg, #FFD400, #F27A01);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  font-size: 48px;
  font-weight: 800;
}

h3 {
  font-size: 32px;
  font-weight: 800;
}

/* Header  */

.navbar {
  /* height: 85px; */
  position: fixed;
  width: 100%;
  z-index: 111;
  top: 0;
  /* background: linear-gradient(122.96deg, #FEF8F4 30.33%, #FFFFFF 119.91%); */
}

.bg-pink {
  /* background: #498FF8; */
  backdrop-filter: blur(10px);
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}


.navbar .nav-link:hover {
  color: #F27A01 ;
  transition: all .2s ease-in-out;
}

.navbar .nav-link img {
  height: 22px;
  transition: all .2s ease-in-out;

}

.navbar .nav-link:hover img {
  transform: translate(0px, -10px);
  transition: all .2s ease-in-out;
}


.navbar-nav {
  position: relative;
}

/* .navbar-nav::after {
  content: "";
  background: #2AE8DC;
  height: 2px;
  width: 100%;
  bottom: -5px;
  left: 0;
  position: absolute;
} */

.navbar-collapse.collapsing {
  transition: none !important;
}


/* Banner  */

.banner {
  /* background-image: url(../public/assets/banner.png); */
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  padding: 120px 0px 100px 0px;
}

.banner h1,
.f-card h1 {
  font-size: 56px;
  font-weight: 800;
  text-transform: capitalize;
  text-align: center;
  /* box-shadow: 0px 0px 200px 0px #000000; */
}

.banner h1 span {
  font-weight: 800;
  background: linear-gradient(144.99deg, #FFBB33 49.9%, #F27A01 85.94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner p {
  font-size: 18px;
  color: rgba(255, 255, 255, .7);
}

.bera {
  height: 30px;
}

.primary-btn {
  color: #0D0703;
  padding: 12px 35px;
  border-radius: 36px;
  font-size: 18px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #FFBB33 0%, #F27A01 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(0px, 0px);
  transition: all .3s ease-in-out;
}


.primary-btn:hover {
  background: linear-gradient(0deg, #FFBB33 0%, #F27A01 100%);
  transform: translate(0px, -5px);
  color: #0D0703;
  transition: all .3s ease-in-out;
}


/* .transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
} */


.z-1 {
  z-index: 1;
}


@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #2B1D11;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar {
    /* position: fixed; */
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .navbar .nav-link {
    color: #fff;
  }

  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 100%;
    left: 0;
    height: 100vh;
    background: rgb(31 29 29 / 95%);
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    transition: all .3s linear;
    display: block !important;
  }


  .cross-btn {
    font-size: 24px;
    color: #F27A01 !important;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  /* .navbar-brand img {
    height: 28px;
  } */

  /* .navbar-nav {
    gap: 20px;
    flex-direction: row;
    margin-top: 25px;
  } */

  .navbar-nav::after {
    content: none;
  }

}

@media (min-width: 992px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}



.g-card {
  background: rgba(255, 255, 255, .1);
  padding: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.g-card h3 {
  color: white;
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 20px;
}

.g-card p {
  font-size: 14px;
  color: rgba(255, 255, 255, .7);
}

.g-img {
  position: absolute;
  top: -90px;
  height: 380px;
}

.g3-img {
  position: absolute;
  top: -90px;
  height: 450px;
}

.g4-img {
  position: absolute;
  top: -110px;
  height: 500px;
}

.g5-img {
  position: absolute;
  top: -180px;
  height: 350px;
  -webkit-animation: tb 3s infinite linear;
  animation: tb 3s infinite linear;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.footer-logo {
  height: 40px;
}

.f-card {
  margin-top: 200px;
  background: rgba(255, 255, 255, .1);
  padding: 32px;
  border-radius: 16px;
  padding-top: 200px;
  position: relative;
}

footer h5 {
  font-size: 16px;
  color: rgba(255, 255, 255, .7);
  font-weight: 400;
  margin-bottom: 20px;
}

footer p {
  font-size: 12px;
  color: rgba(255, 255, 255, .7);
  font-weight: 300;
  margin-bottom: 20px;
}

.sub-footer a {
  font-size: 12px;
  color: rgba(255, 255, 255, .7);
  font-weight: 400;
  transition: all .3s linear;
}

footer ul li a {
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  font-weight: 500;
  display: inline-block;
  transition: all .3s linear;
}

footer ul li a:hover, .sub-footer a:hover {
  color: #F27A01;
  transition: all .3s linear;
}

footer ul li:last-child a {
  margin-bottom: 0;
}

.f-link {
  display: inline-block;
  transition: all .3s ease-in-out;
}

.f-link:hover {
  transform: translate(0px, -5px);
  transition: all .3s ease-in-out;
}

.f-img {
  height: 70px;
}

@-webkit-keyframes tb {
  0% {
    -webkit-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }

  50% {
    -webkit-transform: translate(0, -20px);
    transform: translate(0, -20px);
  }

  100% {
    -webkit-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
}

@keyframes tb {
  0% {
    -webkit-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }

  50% {
    -webkit-transform: translate(0, -20px);
    transform: translate(0, -20px);
  }

  100% {
    -webkit-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
}

@media (max-width: 1160px) {
  .g-img {
    height: 300px;
}
.g3-img {
  height: 380px;
}
.g4-img {
  height: 400px;
}
}

.sn-btn{
  position: relative;
  background: #949494;
}
.soon{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 36px;
    background: #949494;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.sn-btn:hover{
    transform: translate(0 , 0);
    background: #949494;
}

.sn-btn:hover .soon{
  opacity: 1;
}

.disable{
  color: #949494 !important;
}
.disable:hover{
  color: #949494 !important;
}

@media (max-width: 767px) {
  .light-logo{
    height: 18px;
  }
  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 22px;
  }

  .banner {
    padding-top: 100px;
    padding-bottom: 50px;
    background: none;
  }

  .banner h1,
  .f-card h1 {
    font-size: 40px;
  }

  .banner p {
    font-size: 16px;
  }

  .primary-btn {
    padding: 10px 35px;
    font-size: 16px;
  }

  .bera {
    height: 25px;
  }

  .f-img {
    height: 45px;
  }

  .bg-2 {
    padding: 50px 0;
  }

  .g-card {
    min-height: auto;
  }

  .g-card h3 {
    padding-top: 160px;
    font-size: 28px;
  }

  .g-card .row {
    text-align: center;
  }

  .g-card p {
    font-size: 14px;
  }

  .f-card {
    padding: 32px 20px;
    padding-top: 180px;
    margin-top: 0;
  }
  .f-card h1{
    font-size: 28px;
  }

  footer h5 {
    font-size: 18px;
  }

  footer ul li a {
    font-size: 16px;
  }

  .g-img {
    position: absolute;
    top: -110px;
    height: 250px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .g3-img {
    position: absolute;
    top: -90px;
    height: 220px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .g4-img {
    position: absolute;
    top: -110px;
    height: 250px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .g5-img {
    position: absolute;
    top: -30px;
    height: 200px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .g-card {
    padding: 25px
  }
}